.infoWindow {
  position: relative;
  bottom: 200px;
  left: -100px;
  min-width: 240px;
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  padding: 10px;
  z-index: 100;
}
.infoWindow > .arrow {
  bottom: calc(-0.5rem - 1px);
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 83px; // should change if infowindow change the width 200px
}
.infoWindow > .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.infoWindow > .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.pin {
  display: flex;
  align-items: center;
  margin-left: -1rem;
  margin-top: -2.25rem;
}

.pin img {
  margin-right: 10px;
  width: 32px;
  height: 45px;
}

.pin p {
  color: rgb(202, 8, 8);
  font-weight: 800;
  font-size: 0.75rem;
}
.gm-style div {
  border: 0 !important;
}
