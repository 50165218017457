@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.googleAutocomplete__search-input-container {
  position: relative;
  z-index: 100;
}

.googleAutocomplete__search-input,
.googleAutocomplete__search-input:focus,
.googleAutocomplete__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  // padding: 16px;
  // font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.googleAutocomplete__input-search {
  padding-left: 40px !important;
  background: url("../../assets/images/icon-find.png") no-repeat;
  background-position-y: center;
  background-position-x: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.08);
  padding: 1.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.8;
  margin-top: 20px !important;
}

.googleAutocomplete__autocomplete-container {
  flex: content;
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  display: block;
  z-index: 100;
  width: calc(100% - 30px);
  border-radius: 15px;
  background-color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.googleAutocomplete__suggestion-item {
  padding: 8px;
  text-align: left;
  cursor: pointer;
}

.googleAutocomplete__suggestion-item--active {
  padding: 8px;
  text-align: left;
  cursor: pointer;
}
.googleAutocomplete__geocode-result-header {
  font-size: 20px;
  color: #222222;
}

.googleAutocomplete__search-bar-container {
  margin-bottom: -70px;
}
.googleAutocomplete__form-control {
  display: block;
  width: 100% !important;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.5rem 0.1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Helvetica Neue";
}

@include media-breakpoint-up(md) {
  .googleAutocomplete__search-bar-container {
    margin-bottom: 0;
  }
  .googleAutocomplete__form-control {
    height: calc(1.75em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400;
  }
  .googleAutocomplete__input-search {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5 !important;
    margin: 1.5rem 0 0.7rem 0 !important;
    box-shadow: none;
  }
}
