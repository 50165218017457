@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 10px;
  background-color: rgb(214, 214, 214); /* or add it to the track */
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  //   margin-top: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(168, 168, 168);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.listmaplocator__showcase--item img {
  max-width: 150px;
}

@media (min-width: 992px) {
  .listmaplocator__showcase--item.showcase__card--maxSize {
    max-width: 32% !important;
  }
}

.listmaplocator__showcase--item p,
.listmaplocator__showcase--item a {
  font-size: 1.25em;
}

.listmaplocator__statelist--container a {
  font-size: 1em;
  line-height: 2em;
}

.gm-style {
  font-family: "Brandon Grotesque", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.gm-style .gm-style-iw {
  font-size: 1rem;
  font-weight: 500;
}

@include media-breakpoint-up(xs) {
  .listmaplocator__locationListContainer {
    order: 2;
  }
  .listmaplocator__locationMapContainer {
    order: 1;
  }
}

@include media-breakpoint-up(md) {
  .listmaplocator__locationListContainer > div:first-child {
    max-height: 886px;
    overflow-y: auto;
  }
  .listmaplocator__locationListContainer {
    order: 1;
    padding-right: 0 !important;
  }
  .listmaplocator__locationMapContainer {
    order: 2;
  }
}

.fixed-bottom-right {
  position: fixed;
  right: 0;
  bottom: -5px;
  z-index: 1030;
}

@include media-breakpoint-up(lg) {
  .fixed-bottom-right {
    position: fixed;
    right: calc(50% - 480px - 8px);
    bottom: -5px;
    z-index: 1030;
  }
}

@include media-breakpoint-up(xl) {
  .fixed-bottom-right {
    position: fixed;
    right: calc(50% - 570px - 8px);
    bottom: -5px;
    z-index: 1030;
  }
}
