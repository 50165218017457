@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
.locationListItems__item-active {
  border: 2px solid #2db34b !important;
  box-shadow: 0 2px 6px 1px #ddd !important;
}
.locationListItems__item:hover {
  border: 2px solid #2db34b;
  box-shadow: 0 0 15px #033d0f;
}

.locationListItems__item {
  border: 2px solid #ddd;
}
@include media-breakpoint-up(md) {
  .locationListItems__item {
    border: 2px solid #ddd;
  }
  .locationListItems__item-active {
    border: 2px solid #2db34b !important;
    box-shadow: 0 2px 6px 1px #ddd !important;
  }
}
