.languageSwitcher__select {
  height: 40px;
  font-family: "Helvetica Neue";
}

.languageSwitcher__select .dropdown-menu {
  width: 100px;
  min-width: 100px;
  border-radius: 0;
  margin-bottom: -1px !important;
}

.languageSwitcher__select button {
  border: 1px solid #e0e0e0;
  width: 100px;
  border-radius: 0;
  font-size: 0.8rem;
}

.languageSwitcher__select .btn:focus {
  outline: none !important;
  box-shadow: none;
}

.languageSwitcher__select .dropdown-item {
  padding: 3px 10px;
  font-size: 0.8rem;
}

.languageSwitcher__select .btn {
  padding-left: 10px;
  text-align: left;
  background-color: #fff;
}

.languageSwitcher__select .dropdown-toggle::after {
  position: absolute;
  top: 30%;
  right: 0.75rem;
  width: 11px;
  height: 11px;

  background-size: 11px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI4NC45IDI4NC45Ij48cGF0aCBkPSJNMjgyIDc2LjVsLTE0LjItMTQuM2E5IDkgMCAwMC0xMy4xIDBMMTQyLjUgMTc0LjQgMzAuMyA2Mi4yYTkgOSAwIDAwLTEzLjIgMEwzIDc2LjVhOSA5IDAgMDAwIDEzLjFsMTMzIDEzM2E5IDkgMCAwMDEzLjEgMGwxMzMtMTMzYTkgOSAwIDAwMC0xM3oiLz48L3N2Zz4=);
  background-repeat: no-repeat;

  border: 0 !important;
  transition: all 0.2s;
}

.languageSwitcher__select .dropdown-toggle[aria-expanded="false"]::after {
  transform: rotate(-90deg);
}

.languageSwitcher__select .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.languageSwitcher__select .dropdown-item.active,
.dropdown-item:active {
  color: #1ba0e3;
  background-color: #fff;
}
.languageSwitcher__select .dropdown-item:hover,
.dropdown-item:focus {
  color: #1ba0e3;
  background-color: #fff;
}
