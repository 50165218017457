.mobileMenu-subMenus .dropdown-menu {
  border: 0;
  font-size: 1.1rem;
}

.mobileMenu-container {
  top: 0px;
  background: #fff;
  height: calc(100vh);
  position: fixed;
  z-index: 2020;
  right: 0;
  border-left: #000;
  border-left-style: solid;
  min-width: 320px;
  border-left-width: 1px;
}

.collapsed > span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.6%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  background-position-y: 0px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("../../../assets/images/close-cross.svg");
  background-position-y: -5px;
  background-position-x: -5px;
}

.mobileMenu-subMenus .dropdown-toggle::after {
  display: none;
}

div.mobileMenu-subMenus[class~="show"] {
  background-position-y: 3px !important;
  background-image: url("../../../assets/images/arrow-up.png");
  background-position-x: 100%;
}

div.mobileMenu-subMenus:not(.show) {
  background-image: url("../../../assets/images/arrow-down.png");
  background-position-x: 100%;
}

.mobileMenu--navbar-nav {
  min-width: 225px !important;
}
.collapsed > .drawer {
  display: -ms-flexbox;
  display: flex;
}
.drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  background: hsla(0, 0%, 100%, 0.85);
}
