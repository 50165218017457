.coupon_backgroundContainer {
  background-color: #ececec;
  margin-top: 10px;
  padding: 1%;
}

.coupon__promoPrint {
  border: dashed 5px #333;
  padding: 5%;
}
