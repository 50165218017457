@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@include media-breakpoint-up(xs) {
  .aboutEcoAtm__kiosk {
    width: 230px;
  }
}

@include media-breakpoint-up(md) {
  .aboutEcoAtm__kiosk {
    height: 260px;
  }
}
