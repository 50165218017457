@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.main-footer {
  color: #f4f4f4;
  background-color: #313131;
  font-family: "Helvetica Neue";
}
@include media-breakpoint-up(md) {
  .footer--maxSize {
    background-color: red;
  }
}

.main-footer .text-white a {
  color: #fff;
}

.main-footer .text-white svg {
  color: #fff;
}
