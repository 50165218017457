.ecoMenu__navbar {
  background-color: #fff;
  font-weight: 500;
  height: 100px;
  font-size: 0.95rem;
}

.rc-menu-container,
.rc-menu {
  width: 100% !important;
  font-size: 0.98em;
  color: #303030;
  z-index: 500;
}

.rc-menu a {
  color: #5c5c5c;
}

.rc-menu__item--hover {
  background-color: #fff !important;
}

.ecoMenu__appsContainer {
  background-color: #5c5c5c;
  border-radius: 10px;
  width: 140px;
  height: 170px;
  font-size: 0.8em;
  color: #fff;
}
