// Google Fonts
// @import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700);

@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/HelveticaNeueMediumExtended.ttf") format("truetype");
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://cdn.shopify.com/s/files/1/0162/3841/1830/files/BrandonGrotesque-Regular.woff2?78167")
      format("woff2"),
    url("https://cdn.shopify.com/s/files/1/0162/3841/1830/t/2/assets/brandon_reg-webfont.woff?v=11397583347567946253")
      format("woff");
}
@font-face {
  font-family: "Brandon Grotesque";
  src: url("https://cdn.shopify.com/s/files/1/0162/3841/1830/files/BrandonGrotesque-Bold.woff2?78167")
    format("woff2");

  font-weight: 500;
}

// @font-face {
//   font-family: "Brandon Grotesque Regular";
//   src: url("https://cdn.shopify.com/s/files/1/0162/3841/1830/files/BrandonGrotesque-Regular.woff2?78167")
//     format("woff2");

//   font-weight: 500;
// }
// @font-face {
//   font-family: "Brandon Grotesque Medium";
//   src: url("https://cdn.shopify.com/s/files/1/0162/3841/1830/files/BrandonGrotesque-Medium.woff2?78167")
//     format("woff2");

//   font-weight: 500;
// }

// Custom colors
$blue: #0784e9;
$green: #2cb43a;
$body-color: #303030;
$bullseye-red-color: #2cb34a;
$link-color: $bullseye-red-color;
// $bullseye-red-color: #c50518;

// Bootstrap
// Bootstrap - required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$font-family-base: "Brandon Grotesque", "Roboto", "Open Sans", sans-serif;
$enable-responsive-font-sizes: true;
$font-size-base: 1.1rem;

// @include media-breakpoint-up(xs) {
//   .bl-no-gutter-xs {
//     padding-left: 0;
//     padding-right: 0;
//   }
// }

// @include media-breakpoint-up(md) {
//   .bl-no-gutter-md {
//     padding-left: 0;
//     padding-right: 0;
//   }
// }

@include media-breakpoint-down(md) {
  .bl-no-gutter-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
